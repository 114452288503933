import React from "react";
import Image from "../image";
import { Button, makeStyles } from "@material-ui/core";
import { Link, navigate } from "gatsby";
import GlobalConstants, {
  HomePage as Constants,
} from "../../helpers/constants";

type PromoProps = {
  title: string;
  detail: string;
  path: string;
  image: {
    url: string;
    altText?: string;
  };
  popularity?: number;
};

const UseStyles = makeStyles({
  wrapper: {
    border: "1px solid lightgrey",
    textAlign: "center",
    height: "100%",
    display: "flex",
    flexDirection: "column",

    "&:hover": {
      boxShadow: "10px 10px 5px lightgrey",
    },
  },

  image: {
    margin: "0 auto",

    "& img": {
      height: "17.5rem",
      width: "auto",
    },
  },

  title: {
    fontSize: "1.25rem",
    fontWeight: "bold",
    margin: "1rem",
  },

  detail: {
    margin: "1rem",
    flex: "1",
  },

  button: {
    margin: "1rem",

    "& a": {
      textDecoration: "none",
    },

    "& button": {
      padding: "0.5rem 1rem",
    },
  },

  popularity: {
    position: "relative",
    display: "inline-block",
    top: "1.5rem",
    left: "1.5rem",
    textAlign: "left",
  },

  badge: {
    textAlign: "left",
    marginLeft: "0.5rem",
    fontSize: "1.25rem",
    display: "inline-block",
    fontWeight: "bold",
    color: "white",
    padding: "0.5rem 1rem",
    backgroundColor: "#00958D",
    borderRadius: "1.25rem",
  },
});

const Promo = (props: PromoProps): JSX.Element => {
  const Styles = UseStyles();

  return (
    <div className={Styles.wrapper} onClick={() => navigate(props.path)}>
      <div className={Styles.popularity}>
        <div className={Styles.badge}>{props.popularity ?? "?"}</div>
        <div>{GlobalConstants.Popularity}</div>
      </div>
      <div className={Styles.image}>
        <Image url={props.image.url} altText={props.image.altText} />
      </div>
      <div className={Styles.title}>{props.title}</div>
      <div className={Styles.detail}>{props.detail}</div>
      <div className={Styles.button}>
        <Link to={props.path}>
          <Button variant="contained" color="primary">
            {Constants.LearnMore}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default Promo;
