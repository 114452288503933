import { USDToNumber } from "./currency";
import {
  GQLComponent,
  GQLImageContent,
  GQLProductVariant,
  GQLPropertiesTableContent,
  GQLPropertiesTableSection,
  GQLRichTextContent,
  GQLSingleLineContent,
} from "../types";

/* Gets a component by name
 * - components[] -> Array of Crystallize product components
 * - componentName -> The name of the component to return
 */
export const GetComponentByName = (
  components: GQLComponent[],
  componentName: string
): GQLComponent => {
  const Matches = components.filter(function (component) {
    return component.name === componentName;
  });

  return Matches[0];
};

/* Returns the default variant associated with a product
 * - variants[] -> Array of Crystallize product variants
 */
export const GetDefaultVariant = (
  variants: GQLProductVariant[]
): GQLProductVariant => {
  const Matches = variants.filter(function (variant) {
    return variant.isDefault === true;
  });

  return Matches[0];
};

/* Gets the lowest price for a product
 * - priceComponent -> Crystallize properties table component
 */
export const GetLowestPrice = (priceComponent: GQLComponent): number => {
  const PriceCompContent = priceComponent.content as GQLPropertiesTableContent;

  let lowestPrice = Number.MAX_VALUE;
  PriceCompContent.sections?.forEach((section) => {
    section.properties?.forEach((property) => {
      const ValueAsNum = USDToNumber(property.value);
      lowestPrice = ValueAsNum < lowestPrice ? ValueAsNum : lowestPrice;
    });
  });

  return lowestPrice;
};

/* Returns the SKU of a product
 * - infoComponent -> Crystallize properties table component
 */
export const GetSku = (infoComponent: GQLComponent): string => {
  const InfoCompContent = infoComponent.content as GQLPropertiesTableContent;
  const DefaultSection = InfoCompContent.sections[0];

  const SkuProps = DefaultSection.properties.filter((property) => {
    return property.key === "SKU";
  });

  return SkuProps[0].value;
};

/* Gets the pricing model of the product (i.e. 3S2T; 5R)
 * - priceComponent -> Crystallize properties table component
 */
export const GetPriceSchema = (priceComponent: GQLComponent): string => {
  const PriceCompContent = priceComponent.content as GQLPropertiesTableContent;
  const DefaultSection = PriceCompContent.sections[0];
  return DefaultSection.title;
};

/* Returns the url of the product's default image
 * - defaultVariant -> Crystallize default product variant
 */
export const GetDefaultVariantImageUrl = (
  defaultVariant: GQLProductVariant
): string => {
  return defaultVariant?.images[0]?.url;
};

/* Returns the brand name of a product
 * - brandComponent -> Crystallize single line component
 */
export const GetBrandName = (brandComponent: GQLComponent): string => {
  const BrandCompContent = brandComponent.content as GQLSingleLineContent;
  return BrandCompContent.text;
};

/* Detemines if the product is made in the USA
 * - defaultVariant -> Crystallize default product variant
 */
export const IsUsaMade = (defaultVariant: GQLProductVariant): boolean => {
  const HasAttributes =
    defaultVariant.attributes && defaultVariant.attributes.length > 0;

  let usaMade = false;
  if (HasAttributes) {
    const UsaMadeAttributes = defaultVariant.attributes.filter(
      (attribute) => attribute.value === "USA Made"
    );

    usaMade = UsaMadeAttributes.length > 0;
  }

  return usaMade;
};

/* Returns the name of the product
 * - nameComponent -> Crysalize single line content component
 */
export const GetName = (nameComponent: GQLComponent): string => {
  const NameContent = nameComponent.content as GQLSingleLineContent;
  return NameContent.text;
};

/* Returns the description of the product
 * - descriptionComponent -> Crystallize rich text content component
 */
export const GetDescription = (
  descriptionComponent: GQLComponent
): string[] => {
  const DescContent = descriptionComponent.content as GQLRichTextContent;
  return DescContent.plainText;
};

/* Gets the content from an info component for display
 * - infoComponent -> Crystallize properties table component
 */
export const GetInfoDetails = (
  infoComponent: GQLComponent
): GQLPropertiesTableSection => {
  const InfoContent = infoComponent.content as GQLPropertiesTableContent;
  return InfoContent.sections[0];
};

/* Returns the content of a price component
 * - priceComponent -> Crystallize properties table component
 */
export const GetPriceContent = (
  priceComponent: GQLComponent
): GQLPropertiesTableSection => {
  const PriceCompContent = priceComponent?.content as GQLPropertiesTableContent;
  return PriceCompContent?.sections[0];
};

/* Returns the content of a price component
 * - priceComponent -> Crystallize properties table component
 */
export const GetAllPriceSectionsContent = (
  priceComponent: GQLComponent
): GQLPropertiesTableSection[] => {
  const PriceCompContent = priceComponent?.content as GQLPropertiesTableContent;
  return PriceCompContent?.sections;
};

/* Returns the title text for a slide
 * - titleComponent -> Crystallize single line component
 */
export const GetTitle = (titleComponent: GQLComponent): string => {
  const TitleCompContent = titleComponent as GQLSingleLineContent;
  return TitleCompContent.text;
};

/* Returns the brand flavor text for a slide
 * - contentComponent -> Crystallize rich text component
 */
export const GetSingleLineContent = (
  contentComponent: GQLComponent
): string => {
  const ContentCompContent = contentComponent.content as GQLSingleLineContent;
  return ContentCompContent.text;
};

/* Gets the default (first) image from a Crystallize item
 * - imageComponent -> Crystallize image component
 */
export const GetDefaultImageUrl = (imageComponent: GQLComponent): string => {
  const ImageCompContent = imageComponent.content as GQLImageContent;
  return ImageCompContent.firstImage.url;
};
