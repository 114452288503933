import React from "react";
import Layout from "../components/layout";
import Promo from "../components/home/promo";
import GlobalConstants, { HomePage as Constants } from "../helpers/constants";
import { Grid, makeStyles } from "@material-ui/core";
import { GetMostPopularItems } from "../helpers/popularity";
import { GetComponentByName, GetDefaultVariant } from "../helpers/query-data";
import { GQLRichTextContent, GQLSingleLineContent } from "../types";

const UseStyles = makeStyles({
  banner: {
    fontSize: "2rem",
    textAlign: "center",
  },
});

const Index = (): JSX.Element => {
  const Styles = UseStyles();
  const [PromoItems, SetPromoItems] = React.useState([]);

  React.useEffect(() => {
    GetMostPopularItems().then((popularItems) => SetPromoItems(popularItems));
  }, [Object.keys(PromoItems).length]);

  return (
    <Layout currentPage="/">
      <Grid item xs={12}>
        <div className={Styles.banner}>{Constants.BannerText}</div>
      </Grid>

      {PromoItems.map((promoItem, index) => {
        const DefaultVariant = GetDefaultVariant(promoItem.variants);

        const NameCompContent = GetComponentByName(
          promoItem.components,
          GlobalConstants.Name
        )?.content as GQLSingleLineContent;

        const DescCompContent = GetComponentByName(
          promoItem.components,
          GlobalConstants.Description
        )?.content as GQLRichTextContent;

        const Image = {
          url: DefaultVariant.images[0]?.url ?? "",
          altText: DefaultVariant.images[0]?.altText,
        };

        return (
          <Grid key={index} item xs={12} md={6}>
            <Promo
              title={NameCompContent.text}
              detail={DescCompContent.plainText?.join(" ")}
              path={
                GlobalConstants.Products.toLocaleLowerCase() + promoItem.path
              }
              image={Image}
              popularity={promoItem.popularity}
            />
          </Grid>
        );
      })}
    </Layout>
  );
};

export default Index;
